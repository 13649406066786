/* General App Layout */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');

body {
  background-color: black;
  color: #39ff14;
  font-family: 'IBM Plex Mono', monospace;
  margin: 0;
  overflow-y: auto; /* Enable scrolling */
}

/* APP CONNECT */
.connect-wallet-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; /* Full viewport height to vertically center */
  margin: 0; /* Remove any extra margin */
  background-image: url('./assets/wallet-bg.png');
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

.wallet-connect {
  margin-bottom: 20px; /* Space between wallet button and text */
}


.loading-message {
  font-size: 18px;
  color: #007bff;
}

.error-message {
  color: #ff4d4f;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}


.disconnect-button {
  font-size: 12px;
  color: #F37E00;
  font-weight: bold;
  background: black;
  padding: 5px 10px;
  color: #F37E00;
  border: 1px solid #F37E00;
  font-family: 'IBM Plex Mono', monospace;
  text-transform: uppercase;
}

.disconnect-button:hover {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  background: #F37E00;
  padding: 5px 10px;
  border: 1px solid #F37E00;
  font-family: 'IBM Plex Mono', monospace;
  text-transform: uppercase;
}



/* Responsive styles for mobile */
@media (max-width: 768px) {
  header {
    margin-top: 65px; /* Add top margin to header on mobile */
  }

  .wallet-status {
    font-size: 14px; /* Increase font size slightly for better readability */
  }

  .disconnect-button {
    font-size: 14px; /* Adjust font size */
  }
}


/* END */


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  min-height: 100vh; /* Allow content to expand vertically */
  margin: 30px 0;
  
}
.upload-container {
  position: relative;
  display: inline-block;
  padding: 20px 0 0 0;
  width: 100%;
}

.file-input {
  display: none;
}

.upload-button {
  background-color: #ff8c42;
  color: black;
  font-family: "IBM Plex Mono", monospace;
  font-size: 1em;
  padding: 10px 20px;
  width: 90%;

  text-align: center;
  border: none;
  cursor: pointer;
  display: inline-block;
}


/* Header Styling */
.app-header {
  display: flex;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  width: 1060px;
  background-color: black;
  color: #39ff14;
  position: relative;
  border-bottom: none;
  background-image: url('./assets/top-border.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.header-left h1 {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.header-left .icon {
  font-size: 18px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -30px;
  margin-right: -20px;
}

.version {
  font-size: 12px;
  color: #F37E00;
  font-weight: bold;
  text-transform: uppercase;
}

/* Bottom Border */
.header-info {
  font-size: 12px;
}

.dev-info {
  font-size: 10px;
}

.dev-info a {
  text-decoration: none;
  color: #F37E00;
}

.dev-info a:hover {
  color: #00ff00;
}

/* Main Layout */
.main-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: flex-start;
  border: 1px solid #F37E00;
  padding: 50px;
  width: 1000px;
  max-width: 1200px;
}

.canvas-section {
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-container {
  width: 512px;
  height: 512px;
}
.canvas {
  position: relative;
  width: 512px;
  height: 512px;
  border: 1px solid #F37E00;
  background-size: cover;
  overflow: hidden; /* Prevent draggable items from exceeding bounds */
}

.canvas-item {
  position: absolute;
  cursor: move;
    transform: none !important;
  
}

.canvas-item.selected {
  border: 2px dashed #39ff14; /* Highlight selected item */
}

.canvas-container {
  position: relative;
  margin: 20px;
}

.download-button {
  margin-top: 10px;
  background: #00ff00;
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
/* TESTING AREA */ 

.text-item {
  position: absolute;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 20px; /* Initial font size */
  color: #ffffff;
  font-weight: bold;
  background: transparent;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,  
    -1px  1px 0 #000, 
     1px  1px 0 #000;

  /* Ensures text scales proportionally */
  overflow: hidden;
  white-space: nowrap;
}

.text-item span {
  transform-origin: 0 0; /* Top-left origin for scaling */
}

.rotate-handle {
  position: absolute;
  bottom: -20px;
  right: 50%;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  transform: translateX(50%);
}



.text-item.selected {
  border: 2px dashed #39ff14;
}

.text-controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: left;
}

.text-input {
  width: 300px;
  padding: 8px;
  font-size: 16px;
  font-family: 'IBM Plex Mono', monospace;
  color: black;
  border: 1px solid #F37E00;
  background: #fff;
}

.text-input::placeholder {
  color: #888;
}

.add-text-button {
  background: #00ff00;
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  font-family: 'IBM Plex Mono', monospace;
}

.add-image-button {
  background: #00ff00;
  color: black;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin: 20px;
  font-family: 'IBM Plex Mono', monospace;
}

/* TESTING AREA */

.terminal-sep {
  padding: 15px 20px;
  border: solid 1px #F37E00;
  width: 1060px;
  text-align: left;
}

/* Canvas Header Styling */
.canvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #F37E00;
  margin-bottom: 15px;
}

.canvas-title {
  background-color: #F37E00;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  width: 130px;
}

.canvas-title::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #F37E00 transparent transparent transparent;
}

.canvas-size {
  color: #F37E00;
  font-size: 14px;
  text-transform: uppercase;
  background-color: black;
}

.controls-section {
  text-align: left;
  padding-left: 50px;
  margin-top: -10px;
}

/* Section Title Styling */
.section-title {
  text-transform: uppercase;
  font-size: 14px;
  color: #39ff14;
  margin-bottom: 10px;
  border-bottom: 1px solid #F37E00;
  padding-bottom: 5px;
}

/* Terminal */
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1100px;
  text-align: center;
  border: #F37E00 1px solid;
  align-items: center;
}

.terminal {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 14px;
  color: #39ff14;
  background-color: black;
  height: 150px; /* Adjust as needed */
  overflow-y: auto;
  white-space: pre-wrap; /* Keeps newlines */
  text-align: left;
  border-right: 1px solid #F37E00; /* Adds vertical border between items */
  padding: 20px 20px 20px 10px;
}



.links-section {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  align-items: center; /* Center the links horizontally */
  gap: 10px; /* Add space between links */
  padding: 55px 0;
  border-right: 1px solid #F37E00; /* Adds vertical border between items */
}

.links-section a {
  color: #39ff14; /* Neon green */
  text-decoration: none;
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
}

.links-section a:hover {
  text-decoration: underline;
}


.download-button {
  grid-column: 3;
  background: #00ff00;
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 20px;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: bold;
  font-size: 1.3em;
}

/* Controls Styling */
.selector img {
  width: 65px;
  margin: 5px;
  cursor: pointer;
}

.selector img.active {
  border: 1px solid #F37E00;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .App {
    display: block;
    padding: 5px;
    margin: 0;
    overflow: hidden;
  }

  .canvas-container {
    width: 380px;
    height: 380px;
  }
  .canvas {
    width: 380px;
    height: 380px;
  }

  /* HIDDEN */
  .version {
    margin-top: -80px;
    position: absolute;
    margin-right: 70%;
    padding-right: 0;
    clear: both;
    text-align: center;
    flex: none;
  }
  .canvas-size {
    display: none;
  }

  .app-header {
    padding: 10px;
    background-image: url('./assets/top-border-mobile.svg');
    height: 30px;
    width: 100%;
    background-size: contain; /* Adjust to fit smaller screens */
  }

  .header-left h1 {
    font-size: 10px;
    margin: 0;
  }

  .header-right {
    flex-direction: column;
    gap: 5px;
    margin-top: 25px;
    margin-right: 10px;
    text-align: right;
  }

  .main-layout {
    grid-template-columns: 1fr;
    padding: 20px;
    width: 91%;
  }

  .canvas-section {
    margin-bottom: 20px;
  }

  .canvas-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls-section {
    padding-left: 0;
  }

  .terminal-sep {
    width: 91%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 91%;
    padding: 20px;
    overflow: hidden;

  }

  .terminal {
    width: 110%;
    height: auto;
    padding-left: 50px;
    border-right: 0; /* Adds vertical border between items */
    border-bottom: 1px solid #F37E00; /* Adds vertical border between items */
  }

  .links-section {
    border-right: none;
    padding: 10px 0;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 110%;
    border-right: 0; /* Adds vertical border between items */
    border-bottom: 1px solid #F37E00; /* Adds vertical border between items */
  }

  .download-button {
    width: 100%;
    padding: 15px;
    font-size: 1.2em;
  }

  /* Adjust image selectors */
  .selector img {
    width: 50px;
  }
}
